import { render, staticRenderFns } from "./page.bulk_bill.order.vue?vue&type=template&id=d8d2fbe6&scoped=true&lang=true"
import script from "./page.bulk_bill.order.vue?vue&type=script&lang=js"
export * from "./page.bulk_bill.order.vue?vue&type=script&lang=js"
import style0 from "./page.bulk_bill.order.vue?vue&type=style&index=0&id=d8d2fbe6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8d2fbe6",
  null
  
)

export default component.exports